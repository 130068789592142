export default {
	name: 'FormBody',
	props: ['value','group', 'disabled'],
	computed: {
		inputs: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
	},
	methods: {
		getTitle(item){
			let description = item.description.split('><');
			let title = (description.length > 0)?(description[0] + '>'):'';
			return title;
		},
		getDescription(item){
			let description = item.description.split('><');
			let desItem = (description.length > 1)?('<' + description[1]):'';
			return desItem;
		}
	}
};
