import Vue from 'vue';
import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import { areasManager } from '@/store/services/areas_manager';
import { managers } from '@/store/services/managers';
import { isDraft } from '@/store/services/register-status';
//import { responseApi } from '../../format';
import FormHeader from '@/components/inspections/formats/hseq-sso1-f-255/headers/FormHeader.vue';
import FormBody from '@/components/inspections/formats/hseq-sso1-f-255/bodies/FormBody.vue';
import FormImprovement from '@/components/inspections/formats/hseq-sso1-f-255/improvements/FormImprovement.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import register from '@/store/modules/register';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'RegistersCreateHseqSso1F255',
	data: () => ({
		title: '',
		// NEW
		breadcrumbs: [],
		steps: {
			current: 1,
			views: [1],
		},
		clients: [],
		managers: managers,
		services: [],
		areaManager: areasManager[0],
		dialogObservation: false,
		bodyId: null,
		date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substr(0, 10),
		improvements: [],
		isLoadingData: false,
		isLoadingSave: false,
		isLoadingDraft: false,
		validationErrors: [],
	}),
	computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.width < 960;
		},
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['managements', 'costCenters']),
		totalQuestions: function () {
			let total = 0;
			this.inspection.bodies.forEach((body) => {
				total += body.items.length;
			});
			return total;
		},
		totalItemsApply: function () {
			let total = 0;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (['SI', 'NO'].includes(item.value)) {
							total++;
						}
					});
				});
			}
			return total;
		},
		totalItemsResponse: function () {
			let total = 0;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (item.value && item.value != '') {
							total++;
						}
					});
				});
			}
			return total;
		},
		optionsAnswer: function () {
			let options = [];
			if (this.inspection) {
				this.inspection.bodies.forEach((body) => {
					body.options_answer.forEach((option) => {
						let exists = false;
						options.forEach((optionItem) => {
							if (optionItem.value == option.value) {
								exists = true;
							}
						});
						if (!exists) {
							options.push({
								label: option.label,
								value: option.value,
								color: option.color,
								total: 0,
							});
						}
					});
				});

				// SET TOTALS
				this.inspection.bodies.forEach((body) => {
					body.items.forEach((item) => {
						options.forEach((optionItem, indexOI) => {
							if (item.value == optionItem.value) {
								options[indexOI].total++;
							}
						});
					});
				});
			}
			return options;
		},
		totalAnswers: function () {
			let total = 0;
			this.optionsAnswer.forEach((option) => {
				total += option.total;
			});
			return total;
		},
		completeImprovements: function () {
			let result = true;
			this.improvements.forEach((improvement) => {
				if (
					!improvement ||
					!improvement.criticity ||
					!improvement.finding ||
					improvement.finding == '' ||
					improvement.criticity == ''
				) {
					result = false;
				}
			});
			return result;
		},
		validateQuestions: function () {
			let result = true;
			this.inspection.bodyQuestions.forEach((group) => {
				group.items.forEach((item) => {
					if (
						(!item.value || item.value === undefined || item.value == '') &&
						item.required
					) {
						result = false;
					}
				});
			});
			return result;
		},
		validateHeader: function () {
			let result = true;
			this.inspection.headers.forEach((header) => {
				if (
					(!header.value || header.value === undefined || header.value == '') &&
					header.required
				) {
					result = false;
				}
			});
			return result;
		},
		completedQuestionary: function () {
			return (
				this.totalItemsResponse == this.totalQuestions &&
				this.totalItemsResponse > 0 &&
				this.validateHeader &&
				this.completeImprovements &&
				this.validateQuestions
			);
		},
	},
	created() {
		if (this.$route.params.inspectionId) {
			this.getData(this.$route.params.inspectionId);
		} else if (this.$route.params.registerId) {
			this.getDataRegister();
		}
	},
	watch: {
		'steps.current': function (newVal) {
			if (!this.steps.views.includes(newVal)) {
				this.steps.views.push(newVal);
			}
		},
	},
	mounted() {
		this.initialize();
		$EventBus.$emit('showSubNav', false);
	},
	methods: {
		...mapActions('inspection', ['find', 'cleanInspection']),
		...mapActions('register', ['create', 'draft', 'findById']),
		...mapActions('security', ['getManagements']),

		textTransform,

		handleSuccess() {
			this.setBreadCrumbs();
			if (this.$route.params.registerId) {
				this.setRegisterData();
			}
		},
		getDataManagements() {
			this.services = [];
			this.getManagements({
				success: () => {
					this.managements.map((management) => {
						this.services = [...this.services, ...management.costCenters];
					});
				},
				fail: () => {},
			});
		},
		getData(id) {
			this.isLoadingData = true;
			this.find({
				success: () => {
					this.isLoadingData = false;
					this.handleSuccess();
				},
				fail: () => {
					this.isLoadingData = false;
					this.handleFail();
				},
				id: id,
			});
		},
		getDataRegister() {
			this.findById({
				success: () => {
					if (isDraft(this.register.status)) {
						this.getData(this.register.inspection_id);
					} else {
						this.$router.push(
							'/dashboard/inspections/' +
								this.register.inspection_id +
								'/registers'
						);
						$EventBus.$emit(
							'showSnack',
							'warning',
							'Registro no encontrado.'
						);
					}
				},
				fail: () => {},
				id: this.$route.params.registerId,
			});
		},
		setRegisterData() {
			this.inspection.register_id = this.register.id;
			// SET BODIES
			this.register.bodyRegisters.forEach((bodyRegister) => {
				this.inspection.bodies.forEach((bodyGroup, bodyGroupI) => {
					bodyGroup.items.forEach((bodyItem, bodyItemI) => {
						if (bodyItem.id == bodyRegister.body_id) {
							Vue.set(
								this.inspection.bodies[bodyGroupI].items[bodyItemI],
								'value',
								bodyRegister.compliance
							);
							Vue.set(
								this.inspection.bodies[bodyGroupI].items[bodyItemI],
								'observations',
								bodyRegister.observations
							);
						}
					});
				});
			});

			// SET HEADERS
			this.register.registerHeaders.forEach((registerHeader) => {
				this.inspection.headers.forEach((header, headerI) => {
					if (header.id == registerHeader.header_id) {
						Vue.set(
							this.inspection.headers[headerI],
							'value',
							registerHeader.value
						);
						// CLIENT
						if (header.key == 'client') {
							this.clients.forEach((client) => {
								if (client.id == this.register.client_id) {
									Vue.set(
										this.inspection.headers[headerI],
										'value',
										this.register.client_id
									);
								}
							});
						}
						// MANAGER
						if (header.key == 'manager') {
							this.clients.forEach((client) => {
								if (client.id == this.register.manager_id) {
									Vue.set(
										this.inspection.headers[headerI],
										'value',
										this.register.manager_id
									);
								}
							});
						}
					}
				});
			});
		},
		initialize() {
			this.clients = this.areaManager.clients;
		},
		validateImprovement() {
			let result = true;
			this.improvements.forEach((improvement) => {
				if (
					!improvement.criticity ||
					!improvement.finding ||
					improvement.criticity === undefined ||
					improvement.finding === undefined ||
					improvement.criticity == '' ||
					improvement.finding == ''
				) {
					result = false;
				}
			});
			return result;
		},
		validateGroup(index) {
			let result = true;
			this.inspection.bodies[index].items.forEach((body) => {
				if (!body.value || body.value === undefined || body.value == '') {
					result = false;
				}
			});
			return result;
		},
		validateQuestion(index) {
			let result = true;
			this.inspection.bodyQuestions[index].items.forEach((body) => {
				if (!body.value || body.value === undefined || body.value == '') {
					result = false;
				}
			});
			return result;
		},
		getValueHeader(field) {
			let value = '';
			this.inspection.headers.forEach((header) => {
				if (header.key == field) {
					value = header.value;
				}
			});
			return value;
		},
		getClientName() {
			let names = null;
			this.clients.forEach((client) => {
				if (client.id == this.getValueHeader('client')) {
					names = client.name + ' ' + client.last_name;
				}
			});
			return names;
		},
		getManagerName() {
			let names = null;
			this.managers.forEach((manager) => {
				if (manager.id == this.getValueHeader('manager')) {
					names = manager.name + ' ' + manager.last_name;
				}
			});
			return names;
		},
		getDateRegister() {
			let date = null;
			this.inspection.headers.forEach((header) => {
				if (header.key == 'date') {
					date = header.value;
				}
			});
			return date;
		},
		existHeader(keyHeader) {
			let result = false;
			this.inspection.headers.forEach((header) => {
				if (header.key == keyHeader) {
					result = true;
				}
			});
			return result;
		},
		getManagementId(costCenterId) {
			let id = null;
			this.managements.forEach((management) => {
				management.costCenters.forEach((costCenter) => {
					if (costCenter.id == costCenterId) {
						id = management.id;
					}
				});
			});
			return id;
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && found[propFound]) {
					found = found[propFound];
				} else {
					found = null;
				}
			}
			return found || null;
		},
		groupData(status) {
			// INITIAL DATA
			const service_id = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'service',
				propFound: 'value',
			});

			let dataSend = {
				register: {
					id: register.id ? register.id : null,
					inspection_id: this.inspection.id,
					format: this.inspection.format,
					service_id,
					status,
					date: this.findValueInArray({
						items: this.inspection?.headers,
						prop: 'key',
						val: 'date',
						propFound: 'value',
					}),
					manager_id: this.findValueInArray({
						items: this.inspection?.headers,
						prop: 'key',
						val: 'manager',
						propFound: 'value',
					}),
					management_id: this.findValueInArray({
						items: this.costCenters,
						prop: 'id',
						val: service_id,
						propFound: 'management_id',
					}),
					company_id: parseInt(localStorage.getItem('company_id')),
				},
				headers: [],
				bodies: [],
				bodyQuestions: [],
				improvements: this.improvements,
			};

			// SET HEADERS
			this.inspection.headers.forEach((header) => {
				if (header.value && header.value !== '' && header.value !== undefined) {
					dataSend.headers.push({
						header_id: header.id,
						value: header.value.toString(),
					});
				}
			});

			// SET BODIES
			this.inspection.bodies.forEach((body) => {
				body.items.forEach((item) => {
					dataSend.bodies.push({
						compliance: item.value,
						observations: item.observations,
						body_id: item.id,
					});
				});
			});

			// SET QUESTIONS
			this.inspection.bodyQuestions.forEach((group) => {
				group.items.forEach((item) => {
					dataSend.bodyQuestions.push({
						value: item.value,
						body_question_id: item.id,
					});
				});
			});

			return dataSend;
		},
		send(status = 'PENDING') {
			if (status == 'PENDING') {
				this.isLoadingSave = true;
			} else {
				this.isLoadingDraft = true;
			}
			this.validationErrors = [];
			this.create({
				success: () => {
					this.$router.push(
						'/dashboard/inspections/' + this.inspection.format + '/registers'
					);
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				},
				fail: (error) => {
					this.isLoadingSave = false;
					const { response } = error;
					if (response && response?.data?.message) {
						this.validationErrors = Array.isArray(response?.data?.message)
							? response?.data?.message
							: [];
					} else {
						this.validationErrors = [
							'Algo salio mal, no se guardo la información.',
						];
					}
				},
				data: this.groupData(status),
			});
		},
		addObservation(bodyId) {
			this.bodyId = bodyId;
			this.dialogObservation = true;
		},
		closeObservation(status) {
			this.dialogObservation = false;
			if (status) {
				$EventBus.$emit('showSnack', 'success', 'Observación añadida.');
			}
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		setBreadCrumbs() {
			this.breadcrumbs = [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection ? textTransform(this.inspection.name) : '',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsRegisterList',
						params: { id: this.inspection.format },
					},
				},
				{
					text: 'Nuevo registro',
					disabled: true,
					href: '/',
				},
			];
		},
		getLabel(value) {
			let newLabel = '';
			if (value.toLowerCase() == 'si') {
				newLabel = 'Cumplimiento';
			} else if (value.toLowerCase() == 'no') {
				newLabel = 'Incumplimiento';
			} else if (value.toLowerCase() == 'na') {
				newLabel = 'No Aplica';
			}
			return newLabel;
		},
		getPercentCompliance() {
			let percent = 0;
			let itemsYes = 0;
			this.optionsAnswer.forEach((option) => {
				if (option.value.toLowerCase() == 'si') {
					itemsYes = option.total;
				}
			});
			if (this.totalItemsApply > 0) {
				percent = parseFloat(
					parseFloat(
						((100 * itemsYes) / this.totalItemsApply).toString()
					).toFixed(0)
				);
			}
			return percent;
		},
		filterService(item, queryText) {
			return (
				item.pep_code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
					-1 ||
				item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
			);
		},
		setImprovement(data) {
			this.improvements = data;
		},
	},
	components: { FormHeader, FormBody, FormImprovement, ValidationAlert },
	destroyed() {
		this.cleanInspection();
	},
};
