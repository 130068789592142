import InputGlobal from '@/components/inspections/inputs/InputGlobal.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'FormHeader',
	props: ['headers', 'groups', 'disabled'],
	data: () => ({
		inputs: [],
	}),
	created() {
		this.inputs = this.headers;
	},
	components: {
		InputGlobal,
	},
	methods: {
		textTransform,
		
		getInputsGroup(group){
			let items = [];
			this.inputs.forEach(input => {
				if(group.items.includes(input.key)){
					items.push(input);
				}
			});
			return items;
		}
	}
};
