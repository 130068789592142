var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',[(_vm.inspection)?_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s(_vm.inspection ? _vm.inspection.code : '')}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s(_vm.inspection ? _vm.textTransform(_vm.inspection.name) : '')}})]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoadingDraft,"disabled":!_vm.validateHeader || _vm.isLoadingSave},on:{"click":function($event){return _vm.send('DRAFT')}}},[_vm._v(" Guardar como borrador ")])],1)],1):_vm._e(),(_vm.inspection)?_c('v-card',{staticClass:"my-6",attrs:{"elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-6"},[_c('validation-alert',{attrs:{"validationErrors":_vm.validationErrors || []}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"pb-0",attrs:{"elevation":"0","vertical":"","value":_vm.steps.current},model:{value:(_vm.steps.current),callback:function ($$v) {_vm.$set(_vm.steps, "current", $$v)},expression:"steps.current"}},[_c('v-stepper-step',{attrs:{"complete":"","step":"1","edit-icon":_vm.validateHeader
									? 'mdi-check'
									: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == 1
									? 'primary'
									: _vm.validateHeader
									? 'success'
									: 'error'}},[_vm._v(" Inicio ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('form-header',{attrs:{"headers":_vm.inspection.headers,"groups":_vm.inspection.groups}}),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 2}}},[_vm._v(" Siguiente ")])],1),_vm._l((_vm.inspection.bodies),function(group,i){return [_c('v-stepper-step',{key:i + 2,attrs:{"complete":_vm.steps.views.includes(i + 2),"step":i + 2,"edit-icon":_vm.validateGroup(i)
										? 'mdi-check'
										: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == i + 2
										? 'primary'
										: _vm.validateGroup(i)
										? 'success'
										: 'error'}},[_vm._v(" "+_vm._s(_vm.textTransform(group.group))+" ")]),_c('v-stepper-content',{key:i + 2 + 'vsc',attrs:{"step":i + 2}},[_c('form-body',{attrs:{"group":group},model:{value:(group.items),callback:function ($$v) {_vm.$set(group, "items", $$v)},expression:"group.items"}}),(
										_vm.steps.current < _vm.inspection.bodies.length + 2
									)?_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = i + 3}}},[_vm._v(" Siguiente ")]):_vm._e()],1)]}),_vm._l((_vm.inspection.bodyQuestions),function(group,i){return [_c('v-stepper-step',{key:i + 3,attrs:{"complete":_vm.steps.views.includes(i + 3),"step":i + 3,"edit-icon":_vm.validateQuestion(i)
										? 'mdi-check'
										: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == i + 3
										? 'primary'
										: _vm.validateQuestion(i)
										? 'success'
										: 'error'}},[_vm._v(" "+_vm._s(_vm.textTransform(group.group))+" ")]),_c('v-stepper-content',{key:i + 3 + 'vsc',attrs:{"step":i + 3}},[_vm._l((group.items),function(question,j){return _c('div',{key:'group-q-' + j},[_c('v-textarea',{staticClass:"mt-2",attrs:{"label":question.description,"hide-details":"","outlined":"","rows":"3"},model:{value:(question.value),callback:function ($$v) {_vm.$set(question, "value", $$v)},expression:"question.value"}}),(question.note)?_c('p',{staticClass:"mt-2 text--disabled",domProps:{"textContent":_vm._s(question.note)}}):_vm._e()],1)}),(
										_vm.steps.current < _vm.inspection.bodies.length + 3
									)?_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = _vm.inspection.bodies.length + 4}}},[_vm._v(" Siguiente ")]):_vm._e()],2)]}),_c('v-stepper-step',{attrs:{"complete":_vm.steps.views.includes(_vm.inspection.bodies.length + 4),"step":_vm.inspection.bodies.length + 4,"edit-icon":_vm.validateImprovement()
									? 'mdi-check'
									: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == _vm.inspection.bodies.length + 4
									? 'primary'
									: _vm.validateImprovement()
									? 'success'
									: 'error'}},[_vm._v(" Desvios / Oportunidades de mejora ")]),_c('v-stepper-content',{attrs:{"step":_vm.inspection.bodies.length + 4}},[(_vm.steps.current < _vm.inspection.bodies.length + 1)?_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = _vm.i + 5}}},[_vm._v(" Siguiente ")]):_vm._e(),_c('form-improvement',{attrs:{"improvements":_vm.inspection.improvements},on:{"input":_vm.setImprovement}})],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-12 px-6",attrs:{"elevation":"0"}},[_c('v-row',[_c('hr',{staticClass:"w-100"}),_c('v-col',{attrs:{"cols":"12 d-flex justify-end flex-wrap"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.isLoadingDraft,"disabled":!_vm.validateHeader || _vm.isLoadingSave},on:{"click":function($event){return _vm.send('DRAFT')}}},[_vm._v(" Guardar como borrador ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"green","loading":_vm.isLoadingSave,"disabled":!_vm.completedQuestionary || _vm.isLoadingDraft},on:{"click":function($event){return _vm.send('PENDING')}}},[_vm._v(" Finalizar ")])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }