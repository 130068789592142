import { mapState } from 'vuex';
import InputGlobal from '@/components/inspections/inputs/InputGlobal.vue';
export default {
	name: 'FormImprovement',
	props: ['improvements', 'groups', 'inputsExist', 'disabled'],
	data: () => ({
		body: null,
		inputs: []
	}),
	created() {
		this.inspection.improvements.forEach(group => {
			group.items.forEach(item => {
				if(item.body_id){
					this.inputs.push({
						criticity: item.criticity,
						finding: item.finding,
						body_id: item.id
					});
				}
			});	
		})
	},
	computed: {
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		items: function () {
			let itemsData = [];
			this.improvements.map(improvement => {
				improvement.items.map(item => {
					itemsData.push({ 
						id: item.id, 
						description: this.getTitle(item).replace('<h1>', '').replace('</h1>', ''), 
						picture: item.picture,
						disabled: this.existInput(item.id)
					});
				});
			});
			return itemsData;
		},
		options: function () {
			let optionsList = [];
			if(this.improvements.length > 0) {
				optionsList = this.improvements[0].options_answer;
			}
			return optionsList;
		},
		isEditable: function () {
			return ((this.register && this.register.status === 'DRAFT') || !this.register);
		}
	},
	components: {
		InputGlobal,
	},
	methods: {
		getTitle(item){
			let description = item.description.split('><');
			let title = (description.length > 0)?(description[0] + '>'):'';
			return title;
		},
		getDescription(item){
			let description = item.description.split('><');
			let desItem = (description.length > 1)?('<' + description[1]):'';
			return desItem;
		},
		addImprovement(){
			this.inputs.push({
				criticity: null,
				finding: '',
				body_id: this.body
			});
		},
		removeImprovement(bodyId){
			let index = -1;
			this.inputs.forEach((input, inputI) => {
				if(input.body_id === bodyId){
					index = inputI;
				}
			});	
			this.inputs.splice(index, 1);
		},
		findImprovement(bodyId){
			return this.items.find(body => body.id === bodyId);
		},
		existInput(bodyId){
			let findItem = this.inputs.find(input => input.body_id === bodyId);
			return findItem ? true : false
		}
	},
	watch: {
		inputs(newVal){
			this.$emit('input', newVal);
		}
	}
};
